.wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}

.loginBox {
  width: 96vw;
  height: 100vh;
  padding: 0;
  display: flex;
  padding: 0 2vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginButton:hover {
  background-color: #525252 !important;
}

@media only screen and (min-width: 1200px) {
  .wrapper {
    background-color: #202124;
  }
  
  .loginBox {
    width: 30vw;
    height: unset;
    padding: 2vw;
  }
}
