.wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
}

.statusWrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100vw;
  height: 70vh;
}

.formBox {
  width: 100%;
}

.cardBox {
  padding: 0;
  display: flex;
  padding: 2vh 2vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.payButton {
  background-color: #bad85f !important;
  color: #fff !important;
  float: right !important;
  padding: 1vw 2vw !important;
  font-weight: bold !important;
  margin-top: 2vh !important;
}

.payButton:hover {
  background-color: #73d85f !important;
  cursor: pointer;
}

.backButton {
  background-color: #279bab !important;
  color: #fff !important;
  float: left !important;
  padding: 1vw 2vw !important;
  font-weight: bold !important;
  margin-top: 2vh !important;
}

.payButton:hover {
  background-color: #1d6973 !important;
  cursor: pointer;
}

@media only screen and (min-width: 1200px) {
  .wrapper {
    background-color: #202124;
  }

  .cardBox {
    width: 50vw;
    height: unset;
    padding: 2vw;
  }

  .formBox {
    width: 50vw;
  }
}