.wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100vw;
    height: 70vh;
    background-color: #fff;
    text-align: center;
  }

.button {
    background-color: #bad85f !important;
    color: #fff !important;
    float: right !important;
    padding: 1vw 2vw !important;
    font-weight: bold !important;
    margin-top: 2vh !important;
    border-radius: 10px;
    border: none;
}

.formWrapper{
  display: flex;
  justify-content: center;
}

.button:hover{
  cursor: pointer;
}