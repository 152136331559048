.footerImage {
  width: 100%;
  background-color: #279bab;
  margin: 4vh 0 -4px 0;
}

.footerDiv {
  display: flex;
  color: #fff;
  width: 95%;
  background-color: #279bab;
  padding: 3vh 2.5% 3vh 2.5%;
  font-weight: 700;
}

.leftDiv {
  width: 50%;
}

.rightDiv {
  width: 50%;
  text-align: right;
}

@media only screen and (min-width: 1200px) {
  .footerDiv {
    width: 70%;
    padding: 3vh 15% 3vh 15%;
  }
}