.gridWrapper {
  padding: 0 2vw;
  display: flex;
  margin: 10vh 0 0 0;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.buttonDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2vw;
  padding-top: 2vh;
  border-top: 1px solid #279bab;
}

.textDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2vw;
  padding: 1vh;
  border-top: 1px solid #279bab;
}

.backButton {
  background-color: #279bab !important;
  color: #fff !important;
  float: right !important;
  padding: 1vw !important;
  font-weight: bold !important;
}

.backButton:hover {
  background-color: #1d6973 !important;
  cursor: pointer;
}

.saveButton {
  color: #fff !important;
  float: right !important;
  padding: 1vw !important;
  font-weight: bold !important;
}

.saveButton:hover {
  background-color: #73d85f !important;
}

@media only screen and (min-width: 1200px) {
  .gridWrapper {
    padding: 10vh 20vw 0 20vw;
  }
}
