.gridWrapper {
  padding: 9vh 2vw 9vh 2vw;
}

.topDiv {
  border-bottom: 1px solid #279bab;
  display: flex;
  align-items: center;
  padding: 1vh 0;
}

.featuredPicture {
  width: 100%;
}

.itemBox {
  display: flex;
  justify-content: center !important;
  text-align: left !important;
  padding-left: 4vw;
  align-items: center !important;
  flex-direction: column !important;
}

.priceBox {
  display: flex !important;
  justify-content: center !important;
  padding-left: 4vw;
  align-items: center !important;
  flex-direction: column !important;
}

.productCont {
  border-bottom: 1px solid #279bab;
  padding: 2vh 0;
  border-radius: 0;
}

.pictureBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paymentCont {
  border-bottom: 1px solid #279bab;
  padding: 2vh 0;
  border-radius: 0;
}

.inputCont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.paymentImgGrid {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paymentImg {
  max-width: 100% !important;
  height: 4vh;
}

.gridPay {
  padding: 1vh 0;
  display: flex;
  justify-content: space-between;
}

.saveButton {
  color: #fff !important;
  float: right !important;
  padding: 1vw 2vw !important;
  font-weight: bold !important;
}

.saveButton:hover {
  background-color: #73d85f !important;
  cursor: pointer;
}

.deletIcon:hover {
 color: rgb(68, 68, 68);
 cursor: pointer;
}

.noItemDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 43vh;
}

.markdownBox {
  max-height: 85vh !important;
  overflow-y: auto;
  margin-bottom: 1vh;
}

.modal {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 96vw !important;
  background-color: #fff !important;
  box-shadow: 24px !important;
  border-radius: 5px;
  padding: 1vw;
  float: right;
}

@media only screen and (min-width: 1200px) {
  .gridWrapper {
    padding: 15vh 5vw 0 5vw;
  }

  .modal {
    width: 80vw !important;
  }

  .markdownBox {
    max-height: 75vh !important;
  }

  .saveButton {
    padding: 0 2vw !important;
  }
}