.navbar {
  max-height: 8vh;
  z-index: 2;
  text-align: center;
  margin: 0 !important;
  padding: 0 !important;
}

.mid {
  width: 66%;
}

.leftSide {
  width: 17%;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightSide {
  width: 17%;
  height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hyyreLogo{
  max-width: 13vh;
  margin-top: 13vh;
}

@media only screen and (min-width: 1200px) {
  .navbar {
    max-height: 11vh;
  }

  .mid {
    width: 30%;
  }

  .leftSide {
    width: 10%;
    height: 11vh;
  }

  .rightSide {
    width: 58%;
    text-align: center;
    margin-right: 2%;
    display: flex;
    height: 11vh;
  }

  .hyyreLogo {
    max-width: 25vh;
    margin-top: 20vh;
  }
}