.gridWrapper {
  padding: 10vh 2vw 0 2vw;
}

.itemTop {
  border-bottom: 2px solid #279bab;
  padding: 0;
}

.saveButton {
  color: #fff !important;
  float: right !important;
  padding: 1vw !important;
  margin: 2vh 2vw !important;
  font-weight: bold !important;
}

.saveButton:hover {
  background-color: #73d85f !important;
}

.itemBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputCont {
  margin-top: 2vh !important;
  padding-top: 2vh !important;
  border-top: 2px solid #279bab;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
}

.emailCont {
  margin-top: 2vh !important;
  padding-top: 2vh !important;
  border-top: 2px solid #279bab;
  display: flex;
  align-items: center;
}

.textField {
  width: 100%;
  margin-top: 1vh !important;
}

.sendEmailCheckbox {
  width: 50%;
}

@media only screen and (min-width: 1200px) {
  .gridWrapper {
    padding: 20vh 2vw 0 2vw;
  }

  .inputCont {
    justify-content: space-between !important;
    flex-direction: row !important;
  }

  .textField {
    width: 19%;
    margin-top: 0.5vh !important;
  }

  .itemLeft {
    border-right: 3px solid #279bab;
    border-bottom: unset;
    margin: 2vh 0 0 0 !important;
  }

  .itemRight {
    border-bottom: unset;
    margin: 2vh 0 0 0 !important;
  }
}