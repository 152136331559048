.gridWrapper {
  padding: 0 2vw;
}

.addButton {
  border-bottom: 1px solid #279bab;
  min-height: 10vh;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 3vw;
}

.productCont {
  border-bottom: 1px solid #279bab;
  padding: 0.5vh 0;
  border-radius: 0;
  background-color: unset;
}

.pictureBox {
  display: flex;
  justify-content: center;
  align-items: center;
}


.picture {
  width: 100%;
}

.itemBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.productCont:hover {
  cursor: pointer;
  background-color: #f2f2f2;
}

.itemText {
  font-weight: bold !important;
  text-align: center !important;
}

.addIcon {
  color: #bad85f !important;
  height: 4vh !important;
  width: 4vh !important;
}

.addIcon:hover {
  cursor: pointer !important;
}

@media only screen and (min-width: 1200px) {
  .gridWrapper {
    padding: 10vh 20vw;
  }
}
