.gridWrapper {
  padding: 1vh 0;
}

.productCont {
  border-bottom: 1px solid #279bab;
  padding: 0.5vh 0;
  border-radius: 0;
  background-color: unset;
}

.pictureBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.featuredPicture {
  width: 50%;
}

.itemText {
  font-weight: bold !important;
  text-align: center !important;
}

@media only screen and (min-width: 1200px) {
  .gridWrapper {
    padding: 0 2vw;
  }
}
