.productCont {
  padding: 10vh 2vw 0 2vw;
}

.gridTop {
  border-bottom: 1px solid #279bab;
  border-radius: 0;
  min-height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gridPrice {
  border-bottom: 1px solid #279bab;
  border-radius: 0;
  min-height: 5vh;
  padding: 1vh 0;
}

.gridItem {
  border-bottom: 1px solid #279bab;
  border-radius: 0;
  min-height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vh 0;
}

.gridSave {
  min-height: 5vh;
  display: flex;
  align-items: center;
  justify-content: right;
}

.leftPictureDiv {
  width: 50%;
}

.rightPictureDiv {
  width: 50%;
  height: 100%
}

.deletButton {
  background-color: #fd0000 !important;
  color: #fff !important;
  float: right !important;
  padding: 1vw !important;
  font-weight: bold !important;
}

.saveButton {
  background-color: #bad85f !important;
  color: #fff !important;
  float: right !important;
  padding: 1vw !important;
  font-weight: bold !important;
}

.saveButton:hover {
  background-color: #73d85f !important;
}

.deletButton:hover {
  background-color: #d10000 !important;
}

.addIcon {
  color: #bad85f !important;
  height: 4vh !important;
  width: 4vh !important;
}

.addIcon:hover {
  cursor: pointer !important;
}

.deleteIcon:hover {
  cursor: pointer !important;
}

@media only screen and (min-width: 1200px) {
  .productCont {
    padding: 12vh 2vw 0 2vw;
  }
  .deletButton {
    padding: 0.4vw !important;
  }
  .saveButton {
    padding: 0.4vw !important;
  }
}