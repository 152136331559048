.gridWrapper {
  padding: 0 2vw;
  display: flex;
  margin: 10vh 0 0 0;
  align-items: center;
  justify-content: center;
}

.namewrapper {
  border-bottom: 1px solid #279bab;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.nameBox {
  font-weight: bold !important;
  margin: 0.5vh 0 !important;
  width: 50%;
}

.nameText {
  width: 100%;
  font-weight: bold !important;
}

.dateBox {
  font-weight: bold !important;
  margin: 0.5vh 0 !important;
  width: 50%;
  text-align: end;
}

.itemBox {
  display: flex !important;
  justify-content: left !important;
  align-items: center !important;
}

.itemText {
  margin: 0.5vh 0 !important;
}

.h6Text {
  font-weight: bold !important;
  margin: 0.5vh 0 !important;
}

.pictureBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1vh 2vw;
  padding-top: 2vh;
  border-top: 1px solid #279bab;
}

.backButton {
  background-color: #279bab !important;
  color: #fff !important;
  float: right !important;
  padding: 1vw !important;
  font-weight: bold !important;
}

.backButton:hover {
  background-color: #1d6973 !important;
  cursor: pointer;
}

.changeButton {
  background-color: #bad85f !important;
  color: #fff !important;
  float: right !important;
  padding: 1vw !important;
  font-weight: bold !important;
}

.changeButton:hover {
  background-color: #73d85f !important;
}

.delButton {
  background-color: #fd0000 !important;
  color: #fff !important;
  float: right !important;
  padding: 1vw !important;
  font-weight: bold !important;
}

.delButton:hover {
  background-color: #d10000 !important;
}

.modal {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 400px !important;
  background-color: #fff !important;
  box-shadow: 24px !important;
  border-radius: 5px;
  padding: 1vw;
  float: right;
}

.modalText {
  padding-bottom: 1vw !important;
}

@media only screen and (min-width: 1200px) {
  .gridWrapper {
    padding: 10vh 20vw 0 20vw;
  }

  .buttonDiv {
    margin: 1vh 20vw;
  }
}