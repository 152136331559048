.dateWrapper {
  padding: 0 15vw;
}

.dateItem {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 2vh 0 !important;
}

.calendarsPadding {
  width: 100%;
  margin: unset;
}

.colorGrid {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 2vh 0 !important;
  flex-direction: column;
}

@media only screen and (min-width: 1200px) {
  .dateWrapper {
    padding: 0 2vw;
  }
  
  .calendarsPadding {
    margin: 0 2vw;
  }

  .colorGrid {
    justify-content: space-evenly;
    flex-direction: row;
  }  
}
