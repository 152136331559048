.gridWrapper {
  padding: 10vh 2vw 0 2vw;
}

.itemTop {
  border-bottom: 2px solid #279bab;
  padding: 0;
}

.saveButton {
  color: #fff !important;
  float: right !important;
  padding: 1vw !important;
  margin: 2vh 2vw !important;
  font-weight: bold !important;
}

.saveButton:hover {
  background-color: #73d85f !important;
}

.itemBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: 1200px) {
  .gridWrapper {
    padding: 20vh 2vw 0 2vw;
  }

  .itemLeft {
    border-right: 3px solid #279bab;
    border-bottom: unset;
    margin: 2vh 0 0 0 !important;
  }

  .itemRight {
    border-bottom: unset;
    margin: 2vh 0 0 0 !important ;
  }
}